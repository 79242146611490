export const publicPaths = [
  '/sign-in',
  '/sign-up',
  '/forgot-password',
  '/error',
  '/auth/confirm',
  '/auth/confirm-sso',
  '/auth/accept',
  '/api/backoffice/read-cost-calculation-tables'
];

export const isPublicPath = (path: string) => {
  return publicPaths.some(p => path.startsWith(p));
};
